export default [
  {
    text: "Stammdaten",
    icon: "assets/material-bricks-filled.svg",
    items: [
      {
        text: "Fahrzeuge",
        path: "/masterdata/vehicles",
        icon: "assets/vehicle-van-small-filled.svg"
      }
    ]
  },
  {
    text: "Grundlagen",
    icon: "assets/gears.svg",
    items: [
      {
        text: "Fahrzeugtypen",
        path: "/masterdata/vehicle-types",
        icon: "assets/vehicle-van-small-filled-tag.svg"
      },
      {
        text: "Benutzer",
        path: "/masterdata/users",
        icon: "assets/key-user.svg"
      },
      {
        text: "Benutzergruppen",
        path: "/masterdata/user-groups",
        icon: "assets/key-user-group.svg"
      }
    ]
  }
];
