import axios from "axios";
import appConfig from "@/app-config";
// import nav from "./app-intern-navigation"
import {formatDate} from "devextreme/localization";
import moDebugger from "@/utils/moDebugger";
import apirequest from "@/utils/apirequest";

export default {
    _error: null,

    processAuthData(data) {
        appConfig.token = data.token;
        appConfig.userId = data.user.id;
        appConfig.userIdent = data.user.displayname;
        appConfig.email = data.user.email;
        appConfig.userGroupId = data.user.usergroup_id;
        appConfig.adminUser = data.user.is_admin_user;
        appConfig.employeeId = data.user.employee_id;

        data.rights.forEach((right) =>
        {
            let right_id = parseInt(right.id);
            if(!appConfig.rights.includes(right_id))
            {
                appConfig.rights.push(right_id);
            }
        });

        localStorage.setItem('token', appConfig.token);
    },

    loggedIn() {
        return !!appConfig.token;
    },

    async authenticate() {
        const token = localStorage.getItem('token');
        if (!token) return false;
        let config = {
            headers: {
                Authorization: 'Bearer ' + token
            }
        };

        // @todo optimize this. not on every request etc.

        return axios.get(appConfig.apiHost + "auth/check", config).then(response => {
            if (response.status === 200) {
                this.processAuthData(response.data);
                return true;
            } else
                return false;
        }).catch(() => {
            return false;
        });
    },

    async logIn(company, loginName, password)
    {
        const me = this;
        return apirequest.post("auth/login", {
                client_type: 'FRONTEND',
                comp_id: company,
                username: loginName,
                password: password
            },
            {
                'Authorization': null
            },
            function (response, data) {
                moDebugger.moDebug('auth/login/response', response);
                if (response.status === 200)
                {
                    me.processAuthData(response.data);
                    return true;
                }
                else
                {
                    return false;
                }
            },
            function (e) {
                moDebugger.moError('auth/login/catch', e);
                return false;
            });
    },

    async logOut() {
        apirequest.delete('auth/logout').then();
        appConfig.token = null;
        appConfig.userId = null;
        appConfig.userIdent = null;
        appConfig.email = null;
        appConfig.userGroupId = null;
        appConfig.adminUser = null;
        appConfig.employeeId = null;
        appConfig.menu = null;
        localStorage.removeItem('token');
    },

    async changePw(pw) {
        let me = this;
        let result = false;
        await apirequest.patch("v1/renew_password", {password: pw}, {},
            function (response) {
                if (response.status === 200) {
                    result = {
                        isOk: true
                    }
                }
            },
            function (error)
            {
                me._error = error;
                result = {
                    isOk: false
                }
            });

        return result;
    },

    hasRight(right_id) {
        const hasRight = appConfig.rights.includes(parseInt(right_id))
        moDebugger.moDebug('auth/hasRight/result', right_id, appConfig.rights, hasRight);
        return hasRight;
    }
};
