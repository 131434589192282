import axios from "axios";

export default {

    language: 'de',

    googleApi: 'AIzaSyBoGEsyYZ30VYxAm7j0vQFS1kD43s-WhlM',

    token: null,
    userId: null,
    userIdent: null,
    rights: [],

    employeeId: null,

    userGroupId: null,

    adminUser: null,

    dashboardReportId: null,

    workTimeStart: null,
    workTimeTimer: null,
    workTimeId: null,

    projectTimeStart: null,
    projectTimeTimer: null,
    projectTimeId: null,

    menu: null,

    host: process.env.VUE_APP_FRONTEND_URL,
    apiHost: process.env.VUE_APP_BACKEND_URL,

    sendRequest(className, id, attr, value) {
        return new Promise((resolve) => {
            return axios.post(this.apiHost + "validation", {
                className: className,
                id: id,
                attribute: attr,
                value: value
            }, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            }).then(response => {
                if (response.status === 200) {
                    resolve(true);
                }
            }).catch(() => {
                resolve(false);
            });
        });
    },

    updateHeight(component) {
        if (component)
            component.instance.option("height", ((window.innerHeight) - 76));
        else
            return window.innerHeight - 44;
    }
};
