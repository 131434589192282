<template>
  <header class="header-component">
    <DxToolbar class="header-toolbar">
      <DxItem :visible="menuToggleEnabled" location="before" css-class="menu-button">
        <DxButton icon="menu" styling-mode="text" @click="toggleMenuFunc"/>
      </DxItem>
      <DxItem :visiblex="!menuToggleEnabled" css-class="header-title dx-toolbar-label" location="before">
        <div class="header-title logo"><img height="34" alt="logo" width="58" src="assets/mO_Initiale_RGB.svg"></div>
      </DxItem>
<!--      <DxItem location="after">-->
<!--        <DxButton ref="workTimeBtn" text="00:00:00" icon="assets/tools-filled-time.svg" :hint="formatMessage('cpRecordWorkTime')" @click="onWorkTimeClick"></DxButton>-->
<!--      </DxItem>-->
<!--      <DxItem location="after">-->
<!--        <DxButton ref="projectTimeBtn" text="00:00:00" icon="assets/money-time.svg" :hint="formatMessage('cpRecordProjectTime')" @click="onProjectTimeClick"></DxButton>-->
<!--      </DxItem>-->
      <DxItem location="after">
        <DxDropDownButton
            :items="[
                        {id:1, name: formatMessage('cpChangePassword'), icon:'assets/password-user-filled.svg'},
                        {id:2, name:formatMessage('cpLogout'), icon:'assets/button-on-off.svg'}
                     ]"
            :drop-down-options="{ width: 210 }"
            :text="userIdent"
            styling-mode="text"
            icon="assets/work-user-filled.svg"
            display-expr="name"
            key-expr="id"
            @item-click="onUserMenuClick"
        />
      </DxItem>
    </DxToolbar>
  </header>
  <ProjectTimePopup
      :visible="projectTimeVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="400"
      :height="450"
      :title="formatMessage('cpNewProjectEntry')">
    <DxForm :col-count="1" :form-data="projectTimeFormData">
      <DxSimpleItem data-field="business_partner_id" editor-type="dxSelectBox" :editor-options="{dataSource:bsStore, valueExpr:'id' , displayExpr:'displayname'}">
        <DxLabel :text="formatMessage('cpCustomer')"></DxLabel>
      </DxSimpleItem>
      <DxSimpleItem data-field="project_id" editor-type="dxSelectBox" :editor-options="{dataSource:projectStore, valueExpr:'id' , displayExpr:'displayname'}">
        <DxLabel :text="formatMessage('cpProject')"></DxLabel>
      </DxSimpleItem>
      <DxSimpleItem data-field="notes" :col-span="2" editor-type="dxTextArea" :editor-options="{minHeight:150, autoResizeEnabled: true, maxLength:4096}">
        <DxLabel :text="formatMessage('cpNotes')"></DxLabel>
      </DxSimpleItem>
    </DxForm>
    <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{text: formatMessage('actSave')}"
        @click="onProjectTimeSaveClick"
    />
    <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{text:  formatMessage('actCancel')}"
        @click="onProjectTimeCancelClick"
    />
  </ProjectTimePopup>

  <ChangePWPopup
      v-model:visible="changePasswordVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="320"
      :height="500"
      :title="formatMessage('cpChangePassword')">

      <ChangePasswordForm component-name="changepwform"
                          :token="token"
                          :on-saved="onPwSaved"
                          ref="changepwform"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{text: formatMessage('actSave')}"
          @click="onChangePWClick"
      />
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{text:  formatMessage('actCancel')}"
          @click="onCancelClick"
      />
  </ChangePWPopup>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
import auth from "../auth";
import appConfig from "../app-config";
import {useRouter, useRoute} from 'vue-router';
import {DxDropDownButton} from "devextreme-vue";
import {confirm} from "devextreme/ui/dialog"
import {DxPopup as ChangePWPopup, DxPopup as ProjectTimePopup, DxToolbarItem} from "devextreme-vue/popup";
import {DxForm, DxSimpleItem, DxLabel} from "devextreme-vue/form";
import {alert} from "devextreme/ui/dialog";
import {formatDate, formatMessage} from "devextreme/localization";
import axios from "axios";
import "devextreme/data/odata/store";
import ChangePasswordForm from "@/components/change-password-form.vue";

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  },

  data() {
    const router = useRouter();
    const route = useRoute();

    return {
      userIdent: appConfig.userIdent,
      changePasswordVisible: false,
      projectTimeVisible: false,
        token: appConfig.token,
      formData: {pw: '', retypedPw: ''},
      projectTimeFormData: {business_partner_id: null, project_id: null, notes: ''},
      router,
      route,

      projectStore: {
        store: {
          type: 'odata',
          key: 'id',
          url: appConfig.apiHost + 'v1/projects',
          beforeSend: (e) => {
            e.headers = {
              'Authorization': 'Bearer ' + appConfig.token,
              'last-update-ts': formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
            };
            if (e.method === 'MERGE') e.method = 'PATCH';
          }
        },
        select: []
      },

      bsStore: {
        store: {
          type: 'odata',
          key: 'id',
          url: appConfig.apiHost + 'v1/businesspartner',
          beforeSend: (e) => {
            e.headers = {
              'Authorization': 'Bearer ' + appConfig.token,
              'last-update-ts': formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
            };
            if (e.method === 'MERGE') e.method = 'PATCH';
          }
        },
        select: []
      }
    };
  },

  methods: {
    formatMessage,
    onUserMenuClick(e) {
      switch (e.itemData.id) {
        case 1:
          this.formData = {pw: '', retypedPw: ''}
          this.changePasswordVisible = true;
          break;
        case 2:
          confirm(formatMessage('msgWantLogout'), formatMessage('cpLogout')).then((dialogResult) => {
            if (dialogResult) {
              auth.logOut();
              this.router.push({
                path: "/authentication",
                query: {redirect: "/home"}
              });
            }
          });
          break;
      }
    },

    onChangePWClick: async function () {
        let changepw = this.$refs.changepwform;
        changepw.setNewPw();
    },

    onPwSaved() {
        this.changePasswordVisible = false;
    },

    onCancelClick() {
      this.changePasswordVisible = false;
    },

    onProjectTimeSaveClick() {
      if (!appConfig.projectTimeStart) {

        const {business_partner_id, project_id, notes} = this.projectTimeFormData;

        appConfig.projectTimeStart = new Date();
        this.startTimeEntry('PROJEKT', 'PROJEKT', appConfig.projectTimeStart, business_partner_id, project_id, notes);
      } else {
        clearInterval(appConfig.projectTimeTimer)
        appConfig.projectTimeStart = null;
        this.$refs.projectTimeBtn.instance.option('text', '00:00:00');
      }
      this.projectTimeVisible = false;
    },

    onProjectTimeCancelClick() {
      this.projectTimeVisible = false;
    },

    async onWorkTimeClick() {
      if (!appConfig.workTimeStart) {
        appConfig.workTimeStart = new Date();
        this.startTimeEntry('WORK', 'WORK', appConfig.workTimeStart, null, null, null);
      } else {
        this.stopTimeEntry('WORK', 'WORK', appConfig.workTimeId)
        clearInterval(appConfig.workTimeTimer)
        appConfig.workTimeStart = null;
        this.$refs.workTimeBtn.instance.option('text', '00:00:00');
      }
    },

    onProjectTimeClick() {
      if (!appConfig.projectTimeStart)
        this.projectTimeVisible = true;
      else {
        this.stopTimeEntry('PROJECT', 'PROJECT', appConfig.projectTimeId)
        clearInterval(appConfig.projectTimeTimer)
        appConfig.projectTimeStart = null;
        appConfig.projectTimeId = null;
        this.$refs.projectTimeBtn.instance.option('text', '00:00:00');
      }
    },

    workTimerEvent() {
      const date = new Date(null);
      date.setSeconds(Math.abs(new Date() - appConfig.workTimeStart) / 1000);
      this.$refs.workTimeBtn.instance.option('text', date.toISOString().slice(11, 19));
    },

    projectTimerEvent() {
      const date = new Date(null);
      date.setSeconds(Math.abs(new Date() - appConfig.projectTimeStart) / 1000);
      this.$refs.projectTimeBtn.instance.option('text', date.toISOString().slice(11, 19));
    },

    startTimeEntry(displayName, trackType, startTime, businessPartnerId, projectId, notes) {
      axios.post(appConfig.apiHost + "v1/timetracks", {
            "displayname": displayName,
            "business_partner_id": businessPartnerId,
            "project_id": projectId,
            "notes": notes,
            "track_started_at": startTime.toLocaleString(),
            "track_type": trackType,
            "employee_id": appConfig.employeeId,
          },
          {
            headers: {
              'Authorization': 'Bearer ' + appConfig.token
            }
          }).then(response => {
        console.log(response);
        if (response.status === 200) {
          if (trackType === 'WORK') {
            appConfig.workTimeId = response.data.id;
            appConfig.workTimeStart = startTime;
            appConfig.workTimeTimer = setInterval(this.workTimerEvent, 1000);
          } else {
            appConfig.projectTimeId = response.data.id;
            appConfig.projectTimeStart = startTime;
            appConfig.projectTimeTimer = setInterval(this.projectTimerEvent, 1000);
          }
          // @todo if current page is timetrack list automatically reload page
        }
      }).catch(() => {
        return false;
      });
    },


    stopTimeEntry(displayName, trackType, id) {
      const dt = new Date();
      axios.patch(appConfig.apiHost + "v1/timetracks(" + id + ")", {
            "track_ended_at": dt.toLocaleString(),
            "notes": ""
          },
          {
            headers: {
              'Authorization': 'Bearer ' + appConfig.token,
              'last-update-ts': formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
            }
          }).then(response => {
        if (response.status === 200) {
          appConfig.workTimeId = null;
          // @todo if current page is timetrack list automatically reload page
        }
      }).catch(() => {
        return false;
      });
    }
  },

  components: {
      ChangePasswordForm,
    DxLabel,
    DxButton,
    DxToolbar,
    DxItem,
    DxDropDownButton,
    DxToolbarItem,
    ChangePWPopup,
    ProjectTimePopup,
    DxForm,
    DxSimpleItem
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 20px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .DxItem-content {
  padding: 0;
  margin: 0;
}

.header-title .logo {
  padding-left: 15px;
  padding-top: 2px;
}

@media (max-width: 599px) {
  .header-title .logo {
    padding-left: 0;
    padding-top: 2px;
  }
}
</style>


