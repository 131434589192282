import axios from "axios";
import appConfig from "@/app-config";
import appInfo from "@/app-info";
import moDebugger from "@/utils/moDebugger";


export default {

    async get(route, additional_headers, onSuccess, onFailure)
    {
        return this.request('get', route, null, additional_headers, onSuccess, onFailure);
    },


    async post(route, data, additional_headers, onSuccess, onFailure)
    {
        return this.request('post', route, data, additional_headers, onSuccess, onFailure);
    },


    async put(route, data, additional_headers, onSuccess, onFailure)
    {
        return this.request('put', route, data, additional_headers, onSuccess, onFailure);
    },


    async patch(route, data, additional_headers, onSuccess, onFailure)
    {
        return this.request('patch', route, data, additional_headers, onSuccess, onFailure);
    },


    async delete(route, data, additional_headers, onSuccess, onFailure)
    {
        return this.request('delete', route, data, additional_headers, onSuccess, onFailure);
    },


    headers(additional_headers)
    {
        return {
            'Authorization': 'Bearer ' + appConfig.token,
            'ylvi-client': 'Frontend ' + appInfo.title + ' V' + appInfo.version + ' ' + appInfo.buildDate,
            ...additional_headers
        }
    },


    async request(method, route, data, additional_headers, onSuccess, onFailure)
    {
        moDebugger.moDebug('utils/apirequest/request/init', method, route, additional_headers, onSuccess, onFailure);

        const headers = this.headers(additional_headers);

        return axios.request({
                url: appConfig.apiHost + route,
                method: method,
                data: data,
                headers: headers,
            })
            .then(response =>
            {
                moDebugger.moDebug('utils/apirequest/request/then', response);
                if (response.status >= 200 && response.status < 300 && onSuccess)
                {
                    return onSuccess(response, response.data?.d?.results);
                }
            })
            .catch((e) =>
            {
                moDebugger.moWarn('utils/apirequest/request/Error', e);
                // @todo handle expired token
                if(onFailure)
                {
                    return onFailure(e);
                }
            });
    }
};