import auth from "./auth";
import {createRouter, createWebHashHistory} from "vue-router";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import appConfig from "@/app-config";

import {loadMessages, locale} from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import ylviMessages from "@/ylvi_lng.json";
import apirequest from "@/utils/apirequest";

//import nav from "./app-intern-navigation";
import nav2 from "./app-kaestli-navigation";
import moDebugger from "@/utils/moDebugger";

function loadView(view) {
    return () => import (`./views/${view}.vue`);
}

// eslint-disable-next-line no-unused-vars
function addRouteFromMenuItem(items) {
    if (items) {
        items.forEach((item) => {
            if (item.path && item.path !== '') {
                router.addRoute({
                    component: loadView(item.path.replace('/', '')),
                    name: item.path.replace('/', ''),
                    path: item.path,
                    meta: {
                        requiresAuth: true,
                        layout: defaultLayout,
                    }
                });
            }
            if (item.items) addRouteFromMenuItem(item.items);
        });
    }
}

// eslint-disable-next-line no-unused-vars
function getMenu(menu_type = 'FRONTEND') {

    apirequest.get('v1/menu/' + menu_type, {},
        function (response, data) {
            if (document.location.origin === 'https://portal.mobileobjects.de')
                appConfig.menu = nav2; // @todo this needs to be removed
            else
                appConfig.menu = data;

            addRouteFromMenuItem(appConfig.menu);
            document.treeView.option('items', appConfig.menu);
        });
}

loadMessages(deMessages);
loadMessages(ylviMessages);
locale(appConfig.language);

let version = localStorage.getItem('version');
if (version !== appConfig.version) {
    let token = localStorage.getItem('token')
    localStorage.clear();
    localStorage.setItem('token', token);
    localStorage.setItem('version', appConfig.version);
}

const home = 'home';
const router = new createRouter({
    routes: [
        {
            path: "/",
            redirect: home
        },

        {
            path: "/:pathMatch(.*)*",
            redirect: home
        },

        {
            path: "/home",
            name: "home",
            meta: {
                requiresAuth: true,
                layout: defaultLayout
            },
            component: loadView("home/home")
        },
        {
            path: "/authentication",
            name: "login-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Anmeldung"
            },
            component: loadView("authentication/login-form")
        },
        /*{
            path: "/createAccount",
            name: "create-account-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Zugang beantragen"
            },
            component: loadView("authentication/create-account/create-account-form")
        },//*/
        {
            path: "/forgotPassword",
            name: "forgot-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort zurücksetzen"
            },
            component: loadView("authentication/forgot-password/forgot-password-form")
        },
        {
            path: "/resetPassword",
            name: "reset-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort zurücksetzen"
            },
            component: loadView("authentication/reset-password/reset-password-form")
        },
        {
            path: "/changePassword",
            name: "change-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort ändern"
            },
            component: loadView("authentication/change-password-form")
        },
        {
            path: "/verifyEmail",
            name: "verify-email",
            meta: {
                requiresAuth: false,
                layout: simpleLayout
            },
            component: loadView("ylvi/verify-email")
        },
        {
            path: "/reporting/designer",
            name: "reporting/designer",
            meta: {
                requiresAuth: true,
                layout: simpleLayout
            },
            component: loadView("reporting/designer")
        },
        {
            path: "/order/order-view",
            name: "order/order-view",
            meta: {
                requiresAuth: true,
                layout: defaultLayout,

            },
            component: loadView("order/order-view")
        },
    ],
    history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {

    moDebugger.moDebug('router/beforeEach/init', to, from);


    if (from.name === 'login-form' && to.name !== 'reset-password-form' &&
        to.name !== 'forget-password-form' && to.name !== 'verify-email') {
        getMenu();
    }

    if (to.name === "login-form" && auth.loggedIn()) {
        next({name: "home"});
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!auth.loggedIn()) {
            next({
                name: "login-form",
                query: {redirect: to.fullPath}
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
